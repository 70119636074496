<template>
  <div class="user-list">
    <div class="filter-container">
      <div class="brand-filter">
        <span>商家:</span>
        <Dropdown style="margin-left: 6px" @on-click="onBrandItemClick">
          <Button type="primary">
            <span>{{ brandName }}</span>
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="-1" :selected="brandType == -1"
              >全部</DropdownItem
            >
            <DropdownItem
              :name="item.id"
              :selected="brandType == item.id"
              v-for="(item, index) in brandList"
              :key="index"
              >{{ item.name }}</DropdownItem
            >
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="order-filter">
        <span>排序:</span>
        <Dropdown style="margin-left: 6px" @on-click="onOrderTypeItemClick">
          <Button type="primary">
            <span>{{ orderTypeName }}</span>
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem
              name="register_time"
              :selected="orderType == 'register_time'"
              >注册时间</DropdownItem
            >
            <DropdownItem
              name="house_count"
              :selected="orderType == 'house_count'"
              >户型数</DropdownItem
            >
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="descend-container" style="margin-left: 12px">
        <Dropdown @on-click="onDescendItemClick">
          <Button type="primary">
            <span>{{ descendTypeName }}</span>
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="1" :selected="descendType == 1"
              >降序</DropdownItem
            >
            <DropdownItem name="0" :selected="descendType == 0"
              >升序</DropdownItem
            >
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="search-container">
        <Input
          search
          enter-button
          placeholder="输入用户名查询"
          @on-search="onSearch"
        />
      </div>
    </div>
    <Table
      :columns="userColumns"
      :data="userData"
      border
      :max-height="tableMaxHeight"
    >
      <template slot-scope="{ row }" slot="name">
        <strong>{{ row.name }}</strong>
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <Button
          type="primary"
          size="small"
          style="margin: 4px"
          @click="showBuildings(index)"
          >查看</Button
        >
        <Button
          type="primary"
          size="small"
          style="margin: 4px"
          @click="showUserActiveRecords(index)"
          >激活记录</Button
        >
      </template>
    </Table>
    <div style="margin: 20px 12px">
      <div style="display: flex; flex-direction: row-reverse">
        <Page
          :total="totalCount"
          :current="currentPage"
          @on-change="changePage"
          :page-size="pageSize"
          show-total
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinDevice } from "@/util/mixin";
import Store from "@/store";
import api from "@/api";

export default {
  name: "user-list",
  mixins: [mixinDevice],
  data() {
    let columnWidth = Store.state.deviceType == "mobile" ? 140 : "auto";
    return {
      orderType: "register_time",
      descendType: 1,
      userColumns: [
        {
          title: "用户名",
          key: "username",
          width: columnWidth,
        },
        {
          title: "注册日期",
          key: "register_time",
          width: columnWidth,
        },
        {
          title: "到期日期",
          key: "active_expired_time",
          width: columnWidth,
        },
        {
          title: "户型数量",
          key: "house_count",
          width: columnWidth,
        },
        {
          title: "商家名称",
          key: "merchant",
          width: columnWidth,
        },
        {
          title: "操作",
          slot: "action",
          width: columnWidth,
          align: "center",
        },
      ],
      userData: [],
      currentPage: 1,
      totalCount: 0,
      pageSize: 50,
      brandType: -1,
      brandList: [],
      search: "",
    };
  },
  computed: {
    brandName() {
      if (this.brandType == -1) return "全部";
      return this.brandList.filter((item) => {
        return item.id == this.brandType;
      })[0].name;
    },
    descendTypeName() {
      this.$log.debug("descend type: ", this.descendType);
      return this.descendType == 1 ? "降序" : "升序";
    },
    orderTypeName() {
      return this.orderType == "register_time" ? "注册时间" : "户型数";
    },
    tableMaxHeight() {
      return this.isMobile() ? 540 : 700;
    },
  },
  methods: {
    showUserActiveRecords(index) {
      let user = this.userData[index];
      console.log(user);
      this.$router.push({
        name: "user-active-records",
        query: { id: user.user_id, name: user.username },
      });
    },
    showBuildings(idx) {
      this.$log.debug("idx: ", idx);
      let user = this.userData[idx];
      this.$log.debug(user);
      this.$router.push({
        name: "building-list",
        query: { id: user.username },
      });
    },
    changePage(newPage) {
      this.currentPage = newPage;
      this.$log.debug("currentPage: ", this.currentPage);
      this.loadData();
    },
    onBrandItemClick(name) {
      this.$log.debug("brand type: ", name);
      if (name != this.brandType) {
        this.brandType = name;
        this.currentPage = 1;
        this.loadData();
      }
    },
    onOrderTypeItemClick(name) {
      if (name != this.orderType) {
        this.orderType = name;
        this.currentPage = 1;
        this.loadData();
      }
    },
    onDescendItemClick(name) {
      if (name != this.descendType) {
        this.descendType = name;
        this.currentPage = 1;
        this.loadData();
      }
    },
    onSearch(content) {
      this.$log.debug("search content: ", content);
      this.search = content.trim() || "";
      this.currentPage = 1;
      this.loadData();
    },
    loadMerchants() {
      api
        .merchantList()
        .then((res) => {
          this.brandList = res.merchants;
          this.loadData();
        })
        .catch((err) => {
          this.$Message.error("拉取数据失败: " + err.message);
        });
    },
    loadData() {
      let btype = this.brandType;
      api
        .userList(
          btype,
          this.currentPage,
          this.pageSize,
          this.orderType,
          this.descendType,
          this.search
        )
        .then((res) => {
          this.$log.debug(res);
          if (res.users && res.users.length > 0) {
            this.userData = res.users;
            this.totalCount = res.total_count;
          } else {
            this.userData = [];
            this.totalCount = 0;
          }
        })
        .catch((err) => {
          this.$log.debug(err);
        });
    },
  },
  mounted() {
    this.columnWidth = this.isMobile() ? 140 : "auto";
    this.loadMerchants();
  },
};
</script>

<style lang="less" scoped>
.user-list {
  width: 100%;
  padding: 12px;

  .filter-container {
    margin-bottom: 12px;
    padding: 12px 24px;
    border: 1px dashed green;
    display: flex;

    .search-container {
      margin-left: auto;
    }

    .order-filter {
      margin-left: 12px;
    }

    @media screen and (max-width: 750px) {
      flex-wrap: wrap;

      .search-container,
      .descend-container {
        margin: 0;
        margin-top: 12px;
      }
    }
  }
}
</style>